import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../../components/Layout"
import ProductListing from "../../components/store/ProductListing"
import React from "react"
import Seo from "../../components/seo"
import { graphql } from "gatsby"

export const query = graphql`
  query {
    shopifyCollection(handle: { eq: "basses" }) {
      products {
        ...ProductCard
      }
    }
  }
`;

const BassesPage = ({data, pageContext}) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  return (<Layout>
    <Seo title="Basses | Store" />
    
    <div className="bg-dark-night p-5 lg:p-12">
      <div className="max-w-1920 mx-auto">
        <h1 className="page-heading">Basses</h1>
        <div className="mb-10 lg:mb-20"><Breadcrumb crumbs={crumbs} crumbLabel="Basses" crumbSeparator="»" /></div>

        <section className="flex flex-col lg:flex-row">
          <ProductListing products={data?.shopifyCollection?.products} />
        </section>
      </div>
    </div>
  </Layout>)
};

export default BassesPage